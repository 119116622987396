export const RouterKeys = {
    "subjectId":"subjectId",
    "chapterId":"chapterId",
    "testId":"testId",
    "notesId":"notesId",
    "audioId":"audioId",
    "videoId":"videoId"
}

export const decodeSearchString = (key, search) => {
    console.log("key=",key,window.location.search)
    if(search){
        let params = new URLSearchParams(search);
        return params.get(key);
    }
    else {
        let params = new URLSearchParams(window.location.search);
        return params.get(key);
    }
}