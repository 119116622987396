import React, { useEffect, useState } from "react";
import image from "../../image/new imgaes/g12.png";
import API from "../../API";
import { Container, Card, CardHeader, CardBody, Row, Col, Form, FormLabel, FormControl, Button } from "react-bootstrap";
// import SweetAlert from '../../component/SweetAlert';
import { decodeSearchString } from "../../config/RouterConfig";
// import Reveal from "react-reveal/Reveal/";
import lock from "../../image/new imgaes/locktemplate.png";
// import { Card, CardHeader, CardBody, Row, Button, Col, Form, Input } from "reactstrap";


export default function Payment() {
    const [baseUrl] = useState('https://test.payu.in/_payment');
    const [surl] = useState('https://wayyourchild.in/#/paymentSuccess');
    const [curl] = useState('https://wayyourchild.in/#/paymentCancel');
    const [furl] = useState('https://wayyourchild.in/#/paymentFailed');
    const [key] = useState(process.env.REACT_APP_PAYMENT_REGISTRATION_MERCHANT_KEY);
    const [firstName, setFirstName] = useState("");
    const [phone, setPhone] = useState("");
    const [txnid, setTxnId] = useState("");
    const [productinfo, setProductInfo] = useState("");
    const [amount, setAmount] = useState("");
    const [email, setEmail] = useState("");
    const [hash, setHash] = useState("")
    const [newData, setNewData] = useState([]);

    useEffect(() => {
        let search = window.location.hash.split("?")[1];
        let regId = decodeSearchString("regId", search);
        console.log("regId", regId)
        handleHash(regId)
    }, [])

    const handleHash = async (regId) => {
        try {
            await API.getHashFromBackend(regId).then(resp => resp.json()).then((jsonRes) => {
                if (jsonRes.responseCode === 200) {
                    if (jsonRes.result.firstName) {
                        setFirstName(jsonRes.result.firstName)
                    }
                    if (jsonRes.result.phone) {
                        setPhone(jsonRes.result.phone)
                    }
                    if (jsonRes.result.txnId) {
                        setTxnId(jsonRes.result.txnId)
                    }
                    if (jsonRes.result.productInfo) {
                        setProductInfo(jsonRes.result.productInfo)
                    }
                    if (jsonRes.result.amount) {
                        setAmount(jsonRes.result.amount)
                    }
                    if (jsonRes.result.email) {
                        setEmail(jsonRes.result.email)
                    }
                    if (jsonRes.result.hash) {
                        setHash(jsonRes.result.hash)
                    }
                    setNewData(jsonRes.result);
                } else if (jsonRes.error) {
                    console.log('error', jsonRes.errors)
                }
            });
        }
        catch (error) {
            console.log(error);
        }
    }
    console.log("Surl----",surl)

    return (
        <React.Fragment>
            <Row className="template-row">

                <Card className="template">
                    <CardBody className="template-body">
                        <Col className="template-cover">
                            <img className="template-image" src={image}></img>
                        </Col>
                        <h4 style={{ color: "#185ba3" }}>Please Do Payment!</h4>
                        <p className="tempalte-p"><img className="lock-template" src={lock}></img>The payment is perfectly secure.</p>
                        <form action='https://test.payu.in/_payment' method='post'>
                            <input type="hidden" name="firstname" value={firstName} />
                            {/* <input type="hidden" name="lastname" value="Tyagi" /> */}
                            <input type="hidden" name="surl" value={surl} />
                            <input type="hidden" name="phone" value={phone} />
                            <input type="hidden" name="key" value={key} />
                            <input type="hidden" name="hash" value={hash} />
                            <input type="hidden" name="curl" value={curl} />
                            <input type="hidden" name="furl" value={furl} />
                            <input type="hidden" name="txnid" value={txnid} />
                            <input type="hidden" name="productinfo" value={productinfo} />
                            <input type="hidden" name="amount" value={amount} />
                            <input type="hidden" name="email" value={email} />
                            <Button type="submit" className="Success-buttonTwo" value="submit" >Pay Now</Button>
                        </form>
                    </CardBody>
                </Card>
            </Row>
        </React.Fragment>
    )
}








// componentDidMount = () => {
//     let search = window.location.hash.split("?")[1];
//     let regId = decodeSearchString("regId", search);
//     console.log("regId", regId)
//     this.handleHash(regId)
// }

// handleHash = async (regId) => {
//     try {
//         await API.getHashFromBackend(regId).then(resp => resp.json()).then((jsonRes) => {
//             if (jsonRes.responseCode === 200) {
//                 let newData = jsonRes.result
//                 this.setState({ newData });;
//             } else if (jsonRes.error) {
//                 console.log('error', jsonRes.errors)
//             }
//         });
//     }
//     catch (error) {
//         console.log(error);
//     }
// }
// const marchantKey = process.env.REACT_APP_PAYMENT_REGISTRATION_MERCHANT_KEY;
// handlePayment = async () => {
//     let { newData } = this.state;
//     let base_url = 'https://test.payu.in/_payment';
//     const marchantKey = process.env.REACT_APP_PAYMENT_REGISTRATION_MERCHANT_KEY;
//     let body = {
//         "key": marchantKey,
//         "txnid": newData?.txnId,
//         "amount": newData?.amount,
//         "productinfo": newData?.productInfo,
//         "firstname": newData?.firstName,
//         "email": newData?.email,
//         "phone": newData?.phone,
//         "lastname": newData?.lastName,
//         "surl": "https://wayyourchild.in/#/paymentSuccess",
//         "furl": "https://wayyourchild.in/#/paymentFailed",
//         "curl": "https://wayyourchild.in/#/paymentCancel",
//         "hash": newData?.hash
//     }

//     let self = this;
//     self.redirectToPayU(body);

// }
