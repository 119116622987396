import React, { Component } from "react";
import image from "../../image/new imgaes/Group634.png";
import lock  from "../../image/new imgaes/locktemplate.png";
import { Card, CardHeader, CardBody, Row,Button ,Col} from "reactstrap";


class PaymentCancel extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <React.Fragment>
                <Row className="template-row">

                    <Card className="template">
                        <CardBody className="template-body">
                        <Col className="template-cover">
                           <img className="template-image" src={image}></img>
                           </Col>
                            <h4 style={{color:"#185ba3"}}>Payment Cancelled!</h4>
                            <h6 className="template-h6">Oops, that definitely should not happen. We're sorry, please try agin later..</h6>
                            <Button className="Success-buttonTwo">Back to home</Button>
                        </CardBody>
                    </Card>
                </Row>

            </React.Fragment>
        )

    }
}
export default PaymentCancel;