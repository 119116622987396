import React, { Component } from "react";

import { HashRouter, Route, Switch } from "react-router-dom";
import { Home } from "./Home";
import TestimonialTwo from "./component/Testimonial/TestimonialTwo";
import ServiceTwo from "./component/ServiceTwo";
import jhonData from "./component/jhonData";
import SkillTwo from "./component/Skill/SkillTwo";
import Contact from "./component/Contact";
import Features from "./component/Features";
import Blog from "./component/Blog/Blog";
import StudentRegistration from "./component/Student/Student";
import FormSchoolTwo from "./component/FormSchool2";
import NavbarTwo from "./component/Navbar/NavbarTwo";
import Footer from "./component/Footer";
import FeeManagement from "./component/features/FeeManagement";
import SchoolManagement from "./component/features/SchoolManagement";
import Admission from "./component/features/Admission";
import Transport from "./component/features/Transport";
import Notification from "./component/features/Sms";
import Promote from "./component/features/Promote";
import Class from "./component/features/Class";
import Student from "./component/features/Student";
import Leave from "./component/features/Leave";
import Inventory from "./component/features/Inventory";
import Exam from "./component/features/Academic";
import Attendance from "./component/features/Attendance";
import MissionVision from "./component/MissionVision";
import FeaturesTotal from "./component/FeaturesTotal";
import PaymentFailed from "./component/template/PaymentFailed";
import PaymentSuccess from "./component/template/PaymentSuccess";
import PaymentCancel from "./component/template/PaymentCancel";
import PayNow from "./component/template/PayNow";
import Error from "./component/template/Error";
import { createHashHistory } from "history";

// import { loadReCaptcha } from 'react-recaptcha-v3'

const history = createHashHistory();

class App extends Component {

  render() {
    return (
      <React.Fragment>
        <HashRouter history={history}>
          <NavbarTwo mClass="menu_two" mainlogo="logo-way.png" stickylogo="logo-way.png" history={history} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/feeManagement" component={FeeManagement} />
            <Route exact path="/school" component={SchoolManagement} />
            <Route exact path="/transport" component={Transport} />
            <Route exact path="/notification" component={Notification} />
            <Route exact path="/promote" component={Promote} />
            <Route exact path="/inventory" component={Inventory} />
            <Route exact path="/class" component={Class} />
            <Route exact path="/student" component={Student} />
            <Route exact path="/leave" component={Leave} />
            <Route exact path="/admission" component={Admission} />
            <Route exact path="/exam" component={Exam} />
            <Route exact path="/attendance" component={Attendance} />
            <Route exact path="/mission" component={MissionVision} />
            <Route exact path="/testimonials" component={TestimonialTwo} />
            <Route exact path="/about_us" component={ServiceTwo} />
            <Route exact path="/products_services" component={SkillTwo} />
            <Route exact path="/features" render={(props) => <Features jhonData={jhonData} />} />
            <Route exact path="/featurestotal" render={(props) => <FeaturesTotal jhonData={jhonData} />} />
            <Route exact path="/contactus" render={(props) => <Contact jhonData={jhonData} />} />
            <Route exact path="/pricing" component={Blog} />
            <Route exact path="/school_registration" component={FormSchoolTwo} />
            <Route exact path="/student_registration" render={(props) => <StudentRegistration jhonData={jhonData} {...props} />} />{" "}
            <Route exact path="/paymentFailed" component={PaymentFailed} />
            <Route exact path="/paymentSuccess" component={PaymentSuccess} />
            <Route exact path="/paymentCancel" component={PaymentCancel} />
            <Route exact path="/payNow" render={(props) => <PayNow {...props} />} />
            <Route exact path="/Error" component={Error} />
          </Switch>
          <Footer jhonData={jhonData} />
        </HashRouter>
      </React.Fragment>
    );
  }
}

export default App;
