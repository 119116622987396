const baseURL = "https://api.wayyourchild.in/api/";

export default class API {
  static baseURL = "https://api.wayyourchild.in/api/";

  static request(url, method, body = null) {
    const access_token = localStorage.getItem("token");
    return fetch(`${baseURL + url}`, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + (access_token ? access_token : null),
      },
      body: body === null ? null : JSON.stringify(body),
    });
  }

  static postSchoolRegistration(body) {
    return this.request("request-school/web/_create", "POST", body);
  }

  static postStudentRegistration(_id, body) {
    return this.request(`admission/web/_create/${_id}`, "POST", body);
  }

  static listSettingBySchoolId(entity, schoolId) {
    return this.request(`school-setting/${entity}/web?schoolId=${schoolId}`, 'GET')
  }

  static getFeeByClassId(schoolId, classId) {
    return this.request(`registration-setting/web/class-fee?filter={"schoolId":{"$eq":"${schoolId}"},"classId":{"$eq":"${classId}"}}`, 'GET')
  } verifyGoogleRecaptcha
  static getClassFee(filter) {
    return this.request(`registration-setting/web/class-fee?filter=${filter}`, 'GET')
  }

  static verifyGoogleRecaptcha(token) {
    let body = {
      secret: process.env.REACT_APP_GOOGLE_RECAPTCHA_SECRET_KEY,
      response: token
    }
    return fetch('https://www.google.com/recaptcha/api/siteverify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: body
    }).then(resp => resp.json());
  }

  static getHashFromBackend(id) {
    return this.request(`payubiz/hash/${id}`, "POST");
  }

  static paymentPostTestApi(body) {
    console.log('bodyyy', body)
    return fetch('https://test.payu.in/_payment', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: body
    });
  }

  static verifyPayment(body) {
    return this.request(`test.payu.in/merchant/postservice?form=2`, "POST",body);
  }

}


